import React from 'react';
import {Link} from 'gatsby';
import M from '../lib/materialize';
import sidenavImage from '../assets/images/sidenav-image.jpg';
import Img from '../components/atoms/Image';

const menus = [
  {to: '/why-engineer-archive/', text: 'なぜ？'},
  {to: '/how-to-be-engineer-archive/', text: 'どのように？'},
  {to: '/what-enginer-learn-archive/', text: 'なにを？'},
  {to: '/curriculums/', text: 'カリキュラム'},
  {to: '/posts/', text: '記事一覧'},
];

const SideNav = ({amp, onClick}) => {
  return (
    <ul id="side-out" className="sidenav">
      <li>
        <div className="user-view">
          <div className="background">
            <Img amp={amp} src={sidenavImage} />
          </div>
          <div className="sidenav-title">Menu</div>
        </div>
      </li>
      {menus.map((menu, idx) => (
        <li className="nav-link-item" key={idx} onClick={onClick}>
          <Link to={menu.to}>{menu.text}</Link>
        </li>
      ))}
    </ul>
  );
};

const MenuList = ({menus}) => {
  return (
    <ul className="nav-link-list">
      {menus.map((menu, idx) => (
        <li className="nav-link-item" key={idx}>
          <Link to={menu.to}>{menu.text}</Link>
        </li>
      ))}
    </ul>
  );
};

const Navbar = class extends React.PureComponent {
  componentDidMount() {
    const elems = document.querySelectorAll('.sidenav');
    this.sidenav = M.Sidenav.init(elems, {})[0];
  }

  onClick = () => {
    this.sidenav.close();
  };

  render() {
    const {amp} = this.props;
    return (
      <div>
        <div
          className="navigation"
          role="navigation"
          aria-label="main-navigation">
          {!amp && (
            <div
              className="menu hide-on-large-only sidenav-trigger"
              data-target="side-out">
              <a href="#!">
                <i className="material-icons menu-icon">menu</i>
              </a>
            </div>
          )}
          <div className="brand-logo">
            <Link to="/">Webなり</Link>
          </div>
          <div className="nav-links hide-on-med-and-down">
            <MenuList menus={menus} />
          </div>
        </div>
        <SideNav amp={amp} onClick={this.onClick} />
      </div>
    );
  }
};

export default Navbar;
