import React from 'react';
import Layout from '../../components/layouts/Index';
import Breadcrumbs from '../../components/organisms/Breadcrumbs';
import Sidebar from '../../components/organisms/Sidebar';
import Category from '../../../config/category';

class DefaultLayout extends React.PureComponent {
  render() {
    const {amp, baseUrl, layout} = this.props;
    const {tags, breadcrumbs = []} = layout;
    return (
      <Layout amp={amp} baseUrl={baseUrl}>
        <div className="row site-container">
          <div className="col s12 m8 l8" style={{padding: 'unset'}}>
            <Breadcrumbs context={breadcrumbs} />
            <main className="main">{this.props.children}</main>
          </div>
          <div className="col m4 l4">
            <Sidebar tags={tags} categories={Category} />
          </div>
        </div>
      </Layout>
    );
  }
}

export default DefaultLayout;
