import React from 'react';
import {Link} from 'gatsby';
import Img from 'components/atoms/Image';
import defaultImage from 'assets/images/default-image.jpg';
import {meta} from 'config/constants';

const Post = ({amp, post}) => {
  const {title, thumbnail} = post.frontmatter;
  const thumbnailUrl = meta.images.url + thumbnail || defaultImage;
  return (
    <div className="col s12 m4" key={post.id}>
      <div className="post-cards hoverable">
        <div className="card-image">
          <Link to={post.frontmatter.slug || post.fields.slug}>
            <Img amp={amp} src={thumbnailUrl} alt={title} />
          </Link>
        </div>
        <div className="card-content">
          <div className="post-title">
            <Link to={post.frontmatter.slug || post.fields.slug}>
              {post.frontmatter.title}
            </Link>
          </div>
          <div className="post-detail-footer">
          </div>
          <div className="post-detail" />
        </div>
      </div>
    </div>
  );
};

export default Post;
