const {routes} = require('../../config/constants');

const buildPath = paths =>
  [routes.index, ...paths, ''].join('/').replace('//', '/');

const rootPath = () => routes.index;
const aboutPath = () => buildPath(['about']);
const postsPath = () => buildPath([routes.post]);
const categoryPath = category => {
  if (!category) return buildPath([routes.category]);
  return buildPath([routes.category, category]);
};
const tagPath = tag => {
  if (!tag) return buildPath([routes.tag]);
  return buildPath([routes.tag, tag]);
};

const whyArchivePath = () => {
  return buildPath([routes['why-archive']]);
};

const whatArchivePath = () => {
  return buildPath([routes['what-archive']]);
};

const howArchivePath = () => {
  return buildPath([routes['how-archive']]);
};

const curriculumPath = (kind, slug) => {
  if (!kind) return buildPath([routes.curriculums]);
  if (!slug) return buildPath([routes.curriculums, kind]);
  return buildPath([routes.curriculums, kind, slug]);
};

const curriculumBasisPath = (slug) => {
  if (!slug) return curriculumPath('basis')
  return curriculumPath('basis', slug)
};

module.exports = {
  rootPath,
  aboutPath,
  postsPath,
  categoryPath,
  tagPath,
  whyArchivePath,
  whatArchivePath,
  howArchivePath,
  curriculumPath,
  curriculumBasisPath
};
