import React from 'react';
import Profile from '../../components/organisms/Profile';
import i18next from '../../lib/i18next';

const CategoryTree = ({basePath, obj}) => {
  return (
    <>
      {Object.keys(obj).map(key => {
        const item = obj[key];
        if (!item || item.disabled) {
          return <></>;
        }
        const articlePath = item.path
          ? item.path
          : [basePath, item.key].join('/');
        return (
          <ul className="category-list">
            <li className="category-item" key={item.key}>
              <a href={articlePath}>{item.label}</a>
              {item.children && (
                <CategoryTree basePath={basePath} obj={item.children} />
              )}
            </li>
          </ul>
        );
      })}
    </>
  );
};

const Sidebar = ({categories, tags}) => {
  return (
    <div className="sidebar">
      <section className="section">
        <div className="sidebar-section-title">
          <h3>サイト管理人</h3>
        </div>
        <div className="sidebar-section-content">
          <Profile />
        </div>
      </section>
      <section className="section">
        <div className="sidebar-section-title">
          <h3>カテゴリ</h3>
        </div>
        <div className="sidebar-section-content">
          <div className="sidebar-category">
            <CategoryTree basePath="/categories" obj={categories} />
          </div>
        </div>
      </section>
      <section className="section">
        <div className="sidebar-section-title">
          <h3>タグ</h3>
        </div>
        <div className="sidebar-section-content">
          <ul className="tag-list">
            {tags.map(tag => (
              <li key={tag} className="tag-item">
                <a href={`/tags/${tag}`} className="tag-item-link">
                  {i18next.t(`tags.${tag}`)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className="section">
        <div className="sidebar-section-title">
          <h3>アーカイブ</h3>
        </div>
        <div className="sidebar-section-content">
          <ul />
        </div>
      </section>
    </div>
  );
};

export default React.memo(Sidebar);
