const i18next = require('i18next');
const enLocales = require('../locales/en/index');
const jaLocales = require('../locales/ja/index');

i18next
  .init({
    fallbackLng: 'ja',
    debug: true,
    resources: {
      en: {
        translation: enLocales
      },
      ja: {
        translation: jaLocales
      }
    }
  });

module.exports = i18next

