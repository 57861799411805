import React from 'react';
import logoSquare from '../../assets/images/logo.svg';

const Profile = props => {
  return (
    <div className="profile">
      <div className="profile-image">
        <img src={logoSquare} alt="サイト管理人" />
        <p>Webなり</p>
      </div>
      <div className="profile-content">
        <p>
          中小SIer、事業会社のリードエンジニアを経てフリーランスとして独立。
          人にやさしいコードがかけるエンジニアを増やすためにWebエンジニアとして
          知っておきたい情報を発信していきます。
        </p>
        <p>
          管理人に関する記事はコチラ:
          <ul>
            <li>
              <a href="/2017/12/24/story-of-become-web-engineer">
                文系プログラミング未経験者が3年かけてwebエンジニアになるまで
              </a>
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Profile;
