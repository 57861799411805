import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import {batchEventRegister} from 'ga-event-tracker-on-site';
import slack from 'slack-notify';

import Navbar from 'components/Navbar';
import Head from 'components/Head';
import 'assets/stylesheets/index.sass';
import {constants} from 'config/constants';
const notifier = slack(constants.slackWebhookUrl);

export default class IndexLayout extends React.PureComponent {
  componentDidMount() {
    if (process.env.NODE_ENV === 'development') return;
    batchEventRegister(window, constants.trackingSelectors, 'click', {
      afterCallback: (ga, ele, params) => {
        const {eventCategory, eventAction, eventLabel} = params;
        notifier.send({
          channel: '#webnari-event',
          icon_emoji: ':fire:',
          text: 'event fire',
          attachments: [
            {
              color: '#81d4fa',
              fields: [
                {
                  title: 'eventCategory',
                  value: eventCategory,
                },
                {
                  title: 'eventAction',
                  value: eventAction,
                },
                {
                  title: 'eventLabel',
                  value: eventLabel,
                },
              ],
            },
          ],
        });
      },
    });
  }

  render() {
    const {amp, baseUrl, hideNavbar} = this.props;
    const {children} = this.props;
    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
        render={data => (
          <div>
            <Head
              amp={amp}
              baseUrl={baseUrl}
              siteTitle={data.site.siteMetadata.title}
              description={data.site.siteMetadata.description}
            />
            {!hideNavbar && <Navbar amp={amp} />}
            <div>{children}</div>
            <div className="footer">
              <span className="copyright">
                Copyright © 2018 {data.site.siteMetadata.description} All Rights
                Reserved.
              </span>
            </div>
          </div>
        )}
      />
    );
  }
}
