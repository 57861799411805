import React from 'react'

const Breadcrumbs = ({context}) => {
  return (
    <ul className="breadcrumbs">
      {context.map((item, idx) => {
        return (
          <li className="breadcrumbs-item" key={idx}>
            <a href={item.path}>{item.label}</a>
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumbs
